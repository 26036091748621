import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'
import { PostHeader } from '../components/postHeader'

// http://www.dktech.cz/?lang=cz&paged=13
// http://www.dktech.cz/?p=138

const PostC4: FC = () => {
  return (
    <PageTemplate
      prevLink="/post_hlasovani_stehovani_serveru"
      nextLink="/post_budoucnost"
    >
      <PostHeader
        title="C4 na DKTechu"
        link="/post_c4"
        author="Rah"
        date="14.01.2014"
        time="19:01"
        category="Oznámení"
      />
      <p>Zítra v podvečerních hodinách (zhruba po 18hod.) začne přesun L2 serveru z našeho počítače na virtuální server. Počítejte s několika hodinovou odstávkou. Rozhodně doporučujeme si na zítřejší (15.1.2014) večer naplánovat jiný program. Předpokládaný start je někdy v průběhu nočních hodin. Snad to bude do půlnoci.</p>
      <p>Po opětovném spustění poběží dále Lineage 2 C4, kterou si hráči odhlasovali na FB. Jak již bylo předem oznámeno nebude wipe. Všechny postavy, výbava, killy, prostě vše zůstane tak jak to bude v době odlogování.</p>
      <p>Tímto končí můj úkol, přeji všem příjemnou hru a hodně štěstí Rah</p>
      <div className="cleared"></div>
      <span className="linkpages"></span><div className="cleared"></div>

      <div className="cleared"></div>
    </PageTemplate>
  )
}

export default PostC4

export { Head } from '../components/defaultHtmlHead'